import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Travis = () => {
  return (
    <Layout>
      <Head title="Travis Dickinson" />
      <h3>Travis Dickinson</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        San Mateo, CA
      </h4>
      <p>
        <OutboundLink href="https://overtimethinker.livejournal.com/">
          LiveJournal
        </OutboundLink>
      </p>
      <p style={{ maxWidth: 550 }}>
        Growing up, I watched a lot of Saturday morning cartoons. At a young age
        your mind doesn't go past the big booms and action. We just enjoyed the
        thrill of in the moment type of corny one liners or fights between
        villains and heroes. But looking back now at 34 years old, a lot of
        things we saw for what they are are more complex in meaning than what
        they are portrayed as. For example x-men, a school and group of mutants,
        shunned by society and social norms, is not just about injustice for
        mutants. No, my brain took it further than that. I have adhd ocd
        tourettes and anxiety. All the great stuff mixed in a cocktail of
        emotional distress. If I were to seperate all those individually into an
        individuals trait's, it wouldn't be hard to believe that it can be
        looked at as unique. The mutants in x-men are akin to those of us with
        mental illness. Yes I compared X-men to mental illness. Think about it.
        The stigma on mental illness is strong. Yet people see it as a gift as a
        positive like the mutants in X-men. They don't know why they have it or
        feel they shouldn't but they embrace it. And it's what makes them
        unique, except it being super powers. There have been x-men stories
        about the mutants against normal society and I won't take mental illness
        that far, as if we fight physically against people who judge us. But I
        will say metaphorically it's the same idea. We just want a place in the
        world. Be seen as normal or just a part of society and that we have
        gifts that can be positive. The downsides of mental illness
        unfortunately is stronger than the good it provides an individual. Which
        is why I'm x-men even though they save people and do good they're still
        seen as an abomination or a menace. People with adhd have the hyper
        focus and extreme willpower to excel at what they love. The ceo of
        JetBlue has adhd, and basically can do everything he loves, manage a
        huge multibillion dollar business, yet can pay bills withouts help, or
        daily tasks such as laundry. Tim Howard, the goalie of the us soccer
        team is one of the if not the best goalie in pro soccer and that's
        thanks to his undoubted fast reflexes due to his Tourette's. He can
        react so fast and block any shot. Something seen so negatively, can make
        you the best. OCD will help you in interior design, engineering, things
        with organization. Yet it is hell to live with on its own. There's
        always a positive and negative in everything and like the x-men and how
        society views them, we need to stand up ourselves and be better and
        accept and understand mental illness instead of cast them down. We're
        depriving ourselves of a better more productive future the more we
        discourage these talents. Hell if X-men didn't exist the world would be
        a much boring place. And I'd rather be like an x-men than nothing at
        all.
      </p>
    </Layout>
  );
};

export default Travis;
